<template>
  <v-card :loading="loading" :disabled="loading" class="mt-n10 moholand-shadow">
    <v-card-title>
      {{ 'ویرایش "' + title + '"' }}
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        @click="
          $router.replace({
            path: destinationRoute,
            query: $route.query,
          })
        "
      >
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />
        <!-- <multi-input
          class="mt-4"
          v-model="model.images"
          :schema="imagesSchema"
          title="تصاویر"
        /> -->
        <multi-input
          class="mt-4"
          v-model="model.links"
          :schema="linksSchema"
          title="فایل ها"
        />

        <v-row align="center" justify="end" class="my-3">
          <v-btn
            class="mx-4"
            dark
            color="success"
            type="submit"
            :loading="loading"
          >
            ویرایش فایل
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from '@/constants/GlobalConstants';
import MyAxios from '@/constants/MyAxios';
import VFormBase from 'vuetify-form-base';
import { getErrorText } from '@/constants/GlobalConstants';
import { formPostPrepare, formGetPrepare } from '@/constants/PanelFormHandler';
import MultiInput from '../../../../components/MultiInput.vue';
import moment from 'jalali-moment';

export default {
  components: {
    VFormBase,
    MultiInput,
  },
  created() {
    this.getData();
    this.getSellers();
    this.getFeatures();
    this.getSoftwares();
  },

  data() {
    return {
      id: this.$route.params.id,
      // destinationRoute:
      //   this.$route.fullPath.slice(
      //     0,
      //     this.$route.fullPath.indexOf("edit") - 1
      //   ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      destinationRoute: this.$route.fullPath.slice(
        0,
        this.$route.fullPath.indexOf('edit') - 1
      ),
      loading: false,
      title: '',
      product_id: null,
      vendor_id: null,
      model: {
        name: '',
        slug: '',
        author_id: '',
        categories: [],
        media_id: '',
        short_desc: '',
        long_desc: '',
        software_id: '',
        status: '',
        links: [],
        demo: '',
        price: '',
        offer_price: '',
        end_date_offer: '',
        status_desc: '',
        features: [],
        tags: [],
        is_permanent_offer: false,
        orientation: 0,
        // images: [],
      },
      schema: {
        name: {
          type: 'CustomInput',
          label: 'نام فایل',
          rules: [Constants.rules.required],
          required: true,
        },
        author_id: {
          type: 'CustomInput',
          label: 'کاربر قرار دهنده',
          inputType: 'autocomplete',
          items: [],
          rules: [Constants.rules.required],
          itemValue: 'id',
          itemText: 'value',
          loading: true,
        },
        long_desc: {
          type: 'MyTinyMCE',
          label: 'توضیحات',
          rules: [Constants.rules.required],
          required: true,
          col: { cols: 12 },
        },
        media_id: {
          type: 'MyFilePond',
          title: 'تصویر پیشفرض',
          rules: [Constants.rules.required],
          // imageMaxWidth: 300,
          required: true,
        },
        demo: {
          type: 'MyFilePond',
          title: 'اسکرین شات از پروژه',
          // fileTypes: "video/mp4",
          // maxFileSize: "12MB",
          rules: [Constants.rules.required],
          required: true,
        },

        software_id: {
          type: 'CustomInput',
          label: 'دسته بندی',
          inputType: 'autocomplete',
          items: [],
          itemValue: 'id',
          itemText: 'name',
          rules: [Constants.rules.required],
          loading: true,
        },

        features: {
          type: 'CustomInput',
          label: 'ویژگی ها',
          inputType: 'autocomplete',
          items: [],
          itemValue: 'id',
          itemText: 'title',
          multiple: true,
          loading: true,
          required: true,
        },
        orientation: {
          type: 'CustomInput',
          label: 'جهت',
          inputType: 'select',
          items: [
            { id: '0', value: 'افقی' },
            { id: '1', value: 'عمودی' },
            { id: '2', value: 'مربع' },
          ],
          itemValue: 'id',
          itemText: 'value',
          rules: [Constants.rules.required],
          required: true,
        },
        tags: {
          type: 'MyTagSelector',
          label: 'تگ ها',
          rules: [Constants.rules.array_max(4)],
        },
        price: {
          type: 'text-price-field',
          label: 'قیمت',
          rules: [Constants.rules.required_price, Constants.rules.numeric],
          persistentHint: true,
          hint: 'برای فایل رایگان این مقدار را 0 بگذارید.',
          required: true,
          suffix: 'تومان',
        },
        offer_price: {
          type: 'text-price-field',
          label: 'قیمت با تخفیف',
          rules: [Constants.rules.numeric],
          required: true,
          suffix: 'تومان',
        },
        end_date_offer: {
          type: 'ModalDatePicker',
          label: 'تاریخ پایان تخفیف',
          min: moment(new Date()).format('YYYY/MM/DD'),
          required: true,
        },
        is_permanent_offer: {
          type: "CustomInput",
          label: "تخفیف دائمی می باشد",
          inputType:"checkbox"
        },
        status: {
          type: 'CustomInput',
          label: 'وضعیت انتشار',
          inputType: 'select',
          items: [
            { id: '0', value: 'در حال بررسی' },
            { id: '1', value: 'تایید شده' },
            { id: '2', value: 'رد شده' },
          ],
          itemValue: 'id',
          itemText: 'value',
          rules: [Constants.rules.required],
          required: true,
        },
        status_desc: {
          type: 'CustomInput',
          inputType: 'text',
          solo: true,
          flat: true,
          label: 'توضیحات دلیل رد شدن فایل',
          backgroundColor: 'grey lighten-2',
          class: 'required rounded-lg',
        },
      },
      // imagesSchema: {
      //   media_id: {
      //     type: "MyFilePond",
      //     rules: [Constants.rules.required],
      //     required: true,
      //     imageMaxWidth: 300,
      //     imageMaxHeight: 300,
      //     withDelete: false,
      //     col: { cols: 12 },
      //   },
      // },
      linksSchema: {
        media_id: {
          type: 'MyFilePond',
          rules: [Constants.rules.required],
          required: true,
          fileTypes: null,
          maxFileSize: '300MB',
          withDelete: false,
          col: { cols: 12 },
          isRemote: true,
        },
        link: {
          type: 'CustomInput',
          label: 'لینک فایل',
          class: 'ltr',
          col: { cols: 12 },
        },
      },
    };
  },

  methods: {
    getData() {
      this.loading = true;
      MyAxios.get('products/show/' + this.id, {
        params: {
          noPaginate: true,
          with: ['features', 'tags', 'category', 'author'],
        },
      })
        .then(response => {
          this.product_id = response.data?.id || null;
          this.title = response.data.name;
          formGetPrepare(response, this.model);

          this.model.links = response.data.links_media
            ? response.data.links_media
                .filter(val => !!val)
                .map(val => (val.id ? { media_id: val } : { link: val }))
            : [];

          // this.model.images = response.data.images_media
          //   .filter((val) => !!val)
          //   .map((val) => ({
          //     media_id: val,
          //   }));
          this.model.tags = response.data.tags?.map(val => +val.tag_id) || [];

          this.model.features = response.data.features.map(
            val => +val.feature_id
          );

          this.schema.media_id.files = response.data.media || {};

          // this.schema.long_desc.slug = response.data.slug || "123";

          this.schema.demo.files = response.data.demo || {};

          this.model.categories = response.data.category.map(
            val => +val.category_id
          );

          this.searchVendor();
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    getFeatures() {
      if (!this.schema.features.loading) this.schema.features.loading = true;

      MyAxios.get('/features/index', {
        params: {
          noPaginate: true,
        },
      })
        .then(response => {
          this.schema.features.loading = false;
          this.schema.features.items = response.data;
        })
        .catch(error => {
          this.schema.features.loading = false;
        });
    },
    getSoftwares() {
      if (!this.schema.software_id.loading)
        this.schema.software_id.loading = true;

      MyAxios.get('/software/index', {
        params: {
          noPaginate: true,
        },
      })
        .then(response => {
          this.schema.software_id.loading = false;
          this.schema.software_id.items = response.data.map(val => ({
            ...val,
            name: val.name + ' (' + val.format + '.)',
          }));
        })
        .catch(error => {
          this.schema.tags.loading = false;
        });
    },
    getSellers() {
      // Promise.all([
      //   MyAxios.get("/user/list/admin", {
      //     params: {
      //       noPaginate: true,
      //     },
      //   }),
      //   MyAxios.get("/user/list/vendor", {
      //     params: {
      //       noPaginate: true,
      //     },
      //   }),
      // ])
      MyAxios.get('/user/list/vendor', {
        params: {
          noPaginate: true,
          with: 'seller',
        },
      })
        .then(response => {
          this.schema.author_id = {
            ...this.schema.author_id,
            loading: false,
            items: [
              {
                id: 1,
                value: 'ادمین',
              },
              ...response.data.map(val => ({
                id: val.id,
                value:
                  val.first_name +
                  ' ' +
                  val.last_name +
                  (val.seller && val.seller?.store_name
                    ? ' (' + val.seller?.store_name + ')'
                    : ''),
              })),
            ],
          };
        })
        .catch(error => {
          this.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        if (
          !this.model.links.filter(val => !!val?.media_id || val?.link?.length)
            .length
        ) {
          this.$root.$emit('toast', {
            text: 'بخش فایل ها باید حداقل دارای یک فایل باشد.',
          });
          return;
        }

        let price = this.model.price.split(',').join('');
        let offer_price =
          this.model.offer_price && this.model.offer_price.length
            ? this.model.offer_price.split(',').join('')
            : null;

        if (offer_price && offer_price.length && +offer_price > +price) {
          this.$root.$emit('toast', {
            text: 'قیمت با تخفیف نباید بیشتر از قیمت اصلی باشد!',
          });
          return;
        }
        this.loading = true;
        let data = formPostPrepare(this.model);
        data.append('user_id', this.model.author_id);
        data.append('slug', this.model.name.replace(/\/|\s|\./g, '-'));
        data.append('is_permanent_offer', this.model.is_permanent_offer? 1:0);
        data.append('price', price);
        data.append('offer_price', offer_price);

        if (this.model.categories)
          data.append(
            'categories',
            // JSON.stringify([{ category_id: this.model.category_id }])
            JSON.stringify(
              this.model.categories.map(category_id => ({ category_id }))
            )
          );

        if (this.model.features.length > 0)
          data.append(
            'features',
            JSON.stringify(
              this.model.features.map(val => ({ feature_id: val }))
            )
          );

        if (this.model.tags.length > 0)
          data.append(
            'tags',
            JSON.stringify(this.model.tags.map(val => ({ tag_id: val })))
          );

        if (this.model.links.length > 0)
          data.append(
            'links',
            JSON.stringify(
              this.model.links
                .filter(val => val?.media_id || val?.link?.length)
                .map(val => {
                  let is_external =
                    typeof val.link == 'string' && val?.link?.length;
                  return {
                    is_external: !!is_external || undefined,
                    media_id: is_external
                      ? undefined
                      : val.media_id?.id || val.media_id,
                    link: is_external ? val.link : undefined,
                  };
                })
            )
          );

        // data.append(
        //   "images",
        //   JSON.stringify(
        //     this.model.images.map((val) => ({
        //       media_id: val.media_id.id ? val.media_id.id : val.media_id,
        //     }))
        //   )
        // );

        MyAxios.post(`products/${this.id}/update`, data)
          .then(response => {
            // this.$router.go(-1);
            if (this.vendor_id) this.editVendor();
            else this.createVendor();
          })
          .catch(error => {
            this.loading = false;

            if (parseInt(error.response.status) === 500) {
              this.$root.$emit('toast', {
                text:
                  'اسلاگ وارد شده تکراری است یا اشکالی در ثبت اطلاعات به وجود آمده است',
              });
            } else if (parseInt(error.response.status) === 421) {
              this.$root.$emit('toast', {
                text: 'اسلاگ وارد شده تکراری است. لطفا نام فایل را تغییر دهید.',
              });
            } else {
              this.$root.$emit('toast', {
                text: 'مشکلی پیش آمده است. لطفا دوباره تلاش کنید.',
              });
            }
          });
      } else {
        this.$root.$emit('toast', {
          text: 'لطفا اطلاعات را به درستی وارد نمایید.',
        });
      }
    },
    searchVendor() {
      if (this.product_id) {
        MyAxios.get('vendors/index', {
          params: {
            noPaginate: true,
            conditions: {
              product_id: this.product_id,
              user_id: this.model.author_id,
            },
          },
        })
          .then(response => {
            this.vendor_id = response.data.length
              ? response.data.reverse()[0].id
              : null;
          })
          .catch(error => {
            console.log('vendorssssss', error);
          });
      }
    },
    createVendor() {
      let price = this.model.price.split(',').join('');
      let offer_price =
        this.model.offer_price && this.model.offer_price.length
          ? this.model.offer_price.split(',').join('')
          : null;

      let data = new FormData();
      data.append('user_id', this.model.author_id);
      data.append('product_id', this.product_id);
      data.append(
        'price',
        offer_price && offer_price.length ? offer_price : price
      );
      // data.append("commission", 0);

      MyAxios.post('vendors/create', data)
        .then(response => {
          this.loading = false;
          this.$root.$emit('toast', {
            text: 'فایل با موفقیت ویرایش شد',
            type: 'success',
          });
          this.$router.replace({
            path: this.destinationRoute,
            query: this.$route.query || {},
          });
        })
        .catch(error => {
          this.loading = false;

          if (parseInt(error.response.status) === 500) {
            this.$root.$emit('toast', {
              text:
                'اسلاگ وارد شده تکراری است یا اشکالی در ثبت اطلاعات به وجود آمده است',
            });
          } else {
            this.$root.$emit('toast', {
              text: 'مشکلی پیش آمده است. لطفا دوباره تلاش کنید.',
            });
          }
        });
    },
    editVendor() {
      if (this.vendor_id) {
        let price = this.model.price.split(',').join('');
        let offer_price =
          this.model.offer_price && this.model.offer_price.length
            ? this.model.offer_price.split(',').join('')
            : null;

        let data = new FormData();
        data.append('user_id', this.model.author_id);
        data.append('product_id', this.product_id);
        data.append(
          'price',
          offer_price && offer_price.length ? offer_price : price
        );
        // data.append("commission", 0);

        MyAxios.post('vendors/' + this.vendor_id + '/update', data)
          .then(response => {
            this.loading = false;
            this.$root.$emit('toast', {
              text: 'فایل با موفقیت ویرایش شد',
              type: 'success',
            });
            this.$router.replace({
              path: this.destinationRoute,
              query: this.$route.query || {},
            });
          })
          .catch(error => {
            this.loading = false;

            if (parseInt(error.response.status) === 500) {
              this.$root.$emit('toast', {
                text:
                  'اسلاگ وارد شده تکراری است یا اشکالی در ثبت اطلاعات به وجود آمده است',
              });
            } else {
              this.$root.$emit('toast', {
                text: 'مشکلی پیش آمده است. لطفا دوباره تلاش کنید.',
              });
            }
          });
      }
    },
  },
};
</script>

<style></style>
